.training {
  width: 90vw;
  margin: 0 auto 0 auto;
  text-align: center;
  font-family: "Noto Sans";
  color: white;
}

.server-warning {
  color: rgba(255, 255, 255, 0.66);
}

.train-heading {
  color: white;
  min-height: 60px;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.input-container {
  width: 90vw;
  margin: 0 auto 0 auto;
  color: white;
  font-family: "Noto Sans";
}

.training-input {
  background-color: #141414;
  margin-top: 20px;
  height: 75px;
  width: 90vw;
  color: #e7e7e7;
  font-family: "Press Start 2P";
  box-sizing: border-box;
  padding-left: 54px;
  font-size: 24px;
  border: none;
  box-shadow: inset 5px 6px 4px rgba(0, 0, 0, 0.25),
    inset 5px -6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.training-input::placeholder {
  color: #e7e7e7;
}

.train-btn {
  display: flex;
  font-family: "Press Start 2P !important";
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.btn-list {
  width: 90vw;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.usage-guide {
  font-family: "Noto Sans";
  text-align: center;
  color: white;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 500;
  line-height: 158%;
}
