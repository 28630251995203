.section-heading {
  color: white;
  min-height: 60px;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  font-weight: bold;
}
