.terms-and-conditions {
  width: 85vw;
  margin: 0 auto 0 auto;
  color: white;
  font-family: "Noto Sans";
  font-size: 1.1vw;
  margin-top: 6vh;
}

.terms-and-conditions h1 {
  text-align: center;
  font-size: 2vw;
  margin-top: 5vw;
}

.underlined-text {
  text-decoration: underline;
}

.margin-bottom {
  margin-bottom: 2.3vw;
}

.terms-and-conditions a {
  color: white;
}

.email-note,
.thanks-note {
  margin-bottom: 5vw;
}
