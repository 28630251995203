.privacy {
  width: 85vw;
  margin: 0 auto 0 auto;
  color: white;
  font-family: "Noto Sans";
  margin-top: 6vh;
  font-size: 1.1vw;
}

.privacy h1 {
  text-align: center;
  font-size: 2vw;
}

.underlined-text {
  text-decoration: underline;
}

.margin-bottom {
  margin-bottom: 2.3vw;
}

.privacy-note {
  margin-bottom: 4.5vw;
}

.privacy a {
  color: white;
}
