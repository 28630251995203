.metrics {
  margin-top: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #723b7d;
  height: 7.5vh;
  font-family: "Noto Sans";
  color: black;
  letter-spacing: 0.045em;
  font-size: 1.4vw;
  border-width: 2px 0px;
  border-style: solid;
  border-color: #010101;
}

.number {
  color: white;
}
