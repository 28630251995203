textarea {
  width: 100%;
  min-height: 300px;
  overflow: hidden;
  padding: 0;
  font-size: 18px;
  resize: none;
  border: none;
  border-radius: 4px;
  outline: none;
  background: #141414;
  color: rgba(255, 255, 255, 0.6);
  font-family: "Noto Sans";
  font-weight: 500;
}

button {
  width: fit-content;
  max-width: 500px;
}

.container {
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  align-items: flex-end;
  background-color: #141414;
  padding: 30px 40px 20px 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}
