.pricing {
  text-align: center;
  width: 90vw;
  margin: 0 auto 0 auto;
  margin-top: 15vh;
  padding-top: 20px;
  margin-bottom: 20vh;
}

.pricing-heading {
  color: rgba(255, 255, 255, 0.77);
  font-family: "Press Start 2P";
  font-size: 2.2vw;
  margin: 7vh;
}

.price-subheading {
  color: white;
  font-family: "Noto Sans";
  font-weight: 700;
  line-height: 158%;
  font-size: 2vw;
  width: 60%;
  margin: 0 auto 0 auto;
}

.price-description {
  font-family: "Noto Sans";
  color: rgba(255, 255, 255, 0.66);
  text-align: center;
  font-size: 1.2vw;
  margin-bottom: 12vh;
}

.price-list {
  display: flex;
  justify-content: space-around;
}
