.landing {
  display: flex;
  width: 85vw;
  height: 65vh;
  margin: 0 auto 0 auto;
  margin-top: 50px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.landing-left {
  max-width: 50%;
  color: white;
}

.landing-right {
  background-color: black;
  width: 100%;
  min-width: 400px;
  min-height: 400px;
  height: 120;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 4px 4px 4px 8px rgba(0, 0, 0, 0.25);
}

.landing-headline {
  font-family: "Press Start 2P";
  line-height: 171%;
  font-size: 2.4vw;
  margin-bottom: 5vh;
}

.landing-desc {
  color: #bd92c69c;
  font-family: "Noto Sans";
  line-height: 145%;
  font-weight: bold;
  font-size: 1.3vw;
}

.landing-logo {
  width: 50%;
  margin-bottom: 10px;
}

.image-holder {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.relative-btn {
  position: absolute;
  display: flex;
  gap: 40px;
  bottom: 0px;
}

@media screen and (max-width: 1200px) {
  .landing-right {
    min-height: 300px;
  }
}

@media screen and (min-width: 1600px) {
  .landing-right {
    min-height: 500px;
  }
}
