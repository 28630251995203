.feature-card {
  display: flex;
  justify-content: center;
  border: 2px solid #723b7d;
  width: 20vw;
  min-height: 21vw;
  border-radius: 27px;
  filter: drop-shadow(-5px -5px 4px rgba(114, 59, 125, 0.3))
    drop-shadow(5px 5px 4px rgba(114, 59, 125, 0.3));
  font-size: 1.1vw;
}

.feature {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3vw;
  margin-left: 10px;
}

.feature-icon {
  width: 3.5vw;
  height: 3.5vw;
  margin-bottom: 2.2vw;
}

.feature-heading {
  font-family: "Press Start 2P";
  font-size: 1.7vw;
  margin: 0;
}

.feature-description {
  text-align: left;
  font-family: "Noto Sans";
}
