.payment-container {
  width: 90vw;
  margin: 0 auto 0 auto;
  text-align: center;
  color: white;
  font-family: "Noto Sans";
}

.pricecard-list {
  display: flex;
  justify-content: space-around;
}

.payment-heading {
  margin-top: 40px;
}

.payment-description {
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 50px;
}
