.toggle-container {
  position: relative;
  width: 120px;
  height: 44px;
  background-color: #0f0f0f;
  overflow: hidden;
  border-radius: 7px;
}

.toggle-button {
  font-family: "Noto Sans";
  border-radius: 7px;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #723b7d;
  color: #fff;
  border: none;
  padding: 10px 0;
  cursor: pointer;
  transition: left 0.3s ease-in-out;
}

.toggle-container.toggled .toggle-button {
  left: 50%;
}
