.price-card {
  width: 20vw;
  height: 24vw;
  background: #656365;
  border-radius: 20px;
  font-family: "Noto Sans";
  text-align: center;
  position: relative;
  border: 2px solid #000000;
  box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
}

.price-heading {
  font-size: 1.8vw;
  margin-bottom: 3vw;
}

.price-offerings {
  text-align: left;
  font-size: 1.1vw;
  width: 80%;
}

.subscribe-btn {
  position: absolute;
  bottom: 3vw;
  font-family: "Noto Sans";
  margin: auto;
  left: 0;
  right: 0;
}

.peach {
  background-color: #c96557;
}

.purple {
  background-color: #723b7d;
}
