.greeting-container {
  width: 90vw;
  margin: 0 auto 0 auto;
  color: white;
  font-family: "Noto Sans";
}

.greeting-heading {
  color: white;
  font-size: 26px;
  margin-bottom: 10px;
}

.greeting-description {
  color: rgba(255, 255, 255, 0.63);
}
