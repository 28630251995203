.onboarding-container {
  width: 90vw;
  margin: 0 auto 0 auto;
  color: white;
  font-family: "Noto Sans";
}

.onboarding-heading {
  color: white;
  font-size: 26px;
  margin-bottom: 10px;
}

.onboarding-description {
  color: rgba(255, 255, 255, 0.63);
}

.dynamic-function {
  font-size: 26px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dynamic-function-key {
  color: #9e71a7;
}

.dynamic-function-value {
  color: rgba(255, 255, 255, 0.6);
  width: 50%;
  text-align: right;
}
