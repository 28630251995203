.footer {
  width: 85vw;
  margin: 0 auto 0 auto;
  text-align: center;
}

.top-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4vw;
}

.lower-footer {
  color: #5e5e5e;
  font-size: 1.4vw;
  font-family: "Noto Sans";
  margin-bottom: 3vw;
}

.footer-link {
  text-decoration: underline;
  color: #5e5e5e;
}

.footer-logo {
  width: 9vw;
}

.footer-row {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.footer-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2vw;
  font-family: "Noto Sans";
  font-size: 30vw;
}
