.navbar {
  display: flex;
  align-items: center;
  background-color: #191919;
  color: white;
  width: 85vw;
  justify-content: space-between;
  margin: 20px auto 0 auto;
  border-radius: 20px;
  padding: 0 15px 0 15px;
  box-shadow: 10px 10px 4px 2px rgba(0, 0, 0, 0.25);
  font-family: "Press Start 2P";
}

.navbar h1 a {
  color: white;
  text-decoration: none;
}

.nav-links {
  min-width: 45%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-heading {
  cursor: pointer;
}
