.features {
  /* display: flex; */
  text-align: center;
  width: 90vw;
  margin: 0 auto 0 auto;
  justify-content: center;
  color: white;
  font-family: "Noto Sans";
}

.features-heading {
  margin: 0 auto 0 auto;
  width: 50%;
  text-align: center;
  font-weight: bolder;
  line-height: 158%;
  font-size: 2.2vw;
}

.features-description {
  color: rgba(255, 255, 255, 0.66);
  font-size: 1.2vw;
  margin-bottom: 8vh;
}

.feature-list {
  display: flex;
  justify-content: space-around;
}
