.purple-btn {
  background: #723b7d;
  border: 3px solid white;
  min-height: 40px;
  color: white;
  padding: 0 20px 0 20px;
  font-size: 1vw;
  font-family: "Noto Sans";
  cursor: pointer;
}

.purple-btn:hover {
  background: #cba9d1;
  color: black;
}

.text-link {
  background: transparent;
  border: none;
  color: white;
  font-family: "Noto Sans";
  font-size: 1.1vw;
  cursor: pointer;
  text-decoration: none;
}

.text-link:hover {
  color: #723b7d;
}

.black-btn {
  background: #191919;
  border: 3px solid black;
  min-height: 2.7vw;
  color: white;
  padding: 0 30px 0 30px;
  font-size: 1vw;
  font-family: "Noto Sans";
  cursor: pointer;
}

.black-btn:hover {
  background: black;
}

.dope-btn {
  color: white;
  font-family: "Press Start 2P";
  background: #191919;
  border: 2px solid #ffffff;
  min-height: 50px;
  padding: 0 30px 0 30px;
  font-size: 20px;
  cursor: pointer;
}
