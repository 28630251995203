.working {
  margin: 15vh auto 0 auto;
  margin-top: 15vh;
  height: 100%;
  padding: 20px;
  width: 90vw;
  text-align: center;
  color: white;
  font-family: "Press Start 2P";
  margin-bottom: 10vh;
}

.working-headline {
  margin-bottom: 7vh;
  font-size: 2.2vw;
}

.working iframe {
  height: 80vh;
  width: 80%;
  border-radius: 20px;
}

.video-container {
  width: 100%;
}
